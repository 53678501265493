import React, { ButtonHTMLAttributes, FC } from 'react';

interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean;
  name: string;
}

export const Tab: FC<TabProps> = ({ selected, name, ...props }) => {
  return (
    <>
      <button
        className={`px-2 py-2 rounded-md text-14 md:px-4 md:text-16 ${
          selected ? 'text-brand-1 bg-indigo-50 font-bold' : 'text-gray-500'
        }`}
        {...props}
      >
        {name}
      </button>
    </>
  );
};
