import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { postPath } from '../post-config';
import { Post } from '../types';

interface JobCardProps {
  post: Post;
}

export const JobCard: FC<JobCardProps> = ({ post }) => {
  const { push } = useHistory();

  return (
    <button
      className="flex flex-col justify-between p-6 h-80 rounded-lg bg-white shadow-lg"
      onClick={() => push(`/${postPath(post.type)}/${post.id}?local=EN`)}
    >
      <div className="text-left space-y-4">
        {post.thumbnail ? (
          <img
            src={post.thumbnail}
            alt="Job logo"
            className="wh-14 rounded-full"
          />
        ) : (
          <div className="grid place-items-center wh-14 rounded-full bg-gray-200 text-white">
            Logo
          </div>
        )}
        <h1 className="font-bold text-20 truncate-2-lines">{post.title}</h1>
        <p className="text-16 text-gray-500 uppercase truncate-3-lines">
          {post.description}
        </p>
      </div>
      <time className="text-14 text-gray-500 justify-self-end">
        {utcToLocalFormat(post.createdAt, MomentFormat.LL)}
      </time>
    </button>
  );
};
