import GoogleMapReact from 'google-map-react';
import React, { FC, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import useSupercluster from 'use-supercluster';
import { ReactComponent as MapPin } from '../assets/svg/map-pin.svg';
import { fetcher } from '../plugins/react-query';
import { User } from '../types';
import { DirectoryCard } from './DirectoryCard';

export const DirectoryMap = () => {
  const mapRef = useRef<any>();
  const [bounds, setBounds] = useState<number[]>([]);
  const [zoom, setZoom] = useState(10);
  const [selectedUser, setSelectedUser] = useState<User>();
  const { data: users = [] } = useQuery<User[]>('/users', fetcher);

  const points = users
    .filter((u) => u.lat && u.lng)
    .map((user) => ({
      type: 'Feature',
      properties: { ...user },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(user.lng), parseFloat(user.lat)],
      },
    }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  return (
    <div className="h-128 md:h-256 bg-gray-100 w-full">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY ?? '' }}
        defaultCenter={{ lat: 39.8097343, lng: -98.5556199 }}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => (mapRef.current = map)}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster) => {
          const clusterId = cluster.id ?? cluster.properties.id;
          const user = cluster.properties;
          const [lng, lat] = cluster.geometry.coordinates;
          const count = cluster.properties.point_count ?? 1;

          return (
            <Marker
              key={clusterId}
              lng={lng}
              lat={lat}
              count={count}
              total={supercluster.points.length}
              user={user}
              selected={user.id === selectedUser?.id}
              onClickCluster={() => {
                mapRef.current?.panTo({ lng, lat });
                mapRef.current?.setZoom(
                  Math.min(supercluster.getClusterExpansionZoom(clusterId), 20),
                );
              }}
              onClickPoint={() => setSelectedUser(user)}
              onClose={() => setSelectedUser(undefined)}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

interface MarkerProps {
  lng: number;
  lat: number;
  count: number;
  total: number;
  user: User;
  selected: boolean;
  onClickCluster: () => void;
  onClickPoint: () => void;
  onClose: () => void;
}

const Marker: FC<MarkerProps> = ({
  count,
  total,
  user,
  selected,
  onClickCluster,
  onClickPoint,
  onClose,
}) => {
  return count > 1 ? (
    <div
      className="grid place-items-center rounded-full bg-brand-1 font-bold text-base text-white cursor-pointer"
      style={{
        width: `${40 + (count / total) * 40}px`,
        height: `${40 + (count / total) * 40}px`,
      }}
      onClick={onClickCluster}
    >
      {count}
    </div>
  ) : (
    <>
      <MapPin className="cursor-pointer" onClick={onClickPoint} />
      {selected && (
        <div
          className="relative z-50 left-10 bottom-28 min-w-64"
          onClick={onClose}
        >
          <DirectoryCard user={user} />
        </div>
      )}
    </>
  );
};
