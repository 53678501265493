import React from 'react';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';

import Company1 from '../assets/img/company1.png';
import Company2 from '../assets/img/company2.png';
import Company3 from '../assets/img/company3.png';
import Company4 from '../assets/img/company4.png';
import Company5 from '../assets/img/company5.png';
import Company6 from '../assets/img/company6.png';
import Company7 from '../assets/img/company7.png';
import Company8 from '../assets/img/company8.png';
import Company9 from '../assets/img/company9.png';
import Company10 from '../assets/img/company10.png';
import Company11 from '../assets/img/company11.png';
import Company12 from '../assets/img/company12.png';

export const relatedData = [
  {
    id: 1,
    link: 'https://www.scourt.go.kr/supreme/supreme.jsp',
    image: Company2,
  },
  {
    id: 2,
    link: 'https://www.ccourt.go.kr/site/kor/main.do',
    image: Company3,
  },
  {
    id: 3,
    link: 'https://www.moj.go.kr/moj/index.do',
    image: Company4,
  },
  {
    id: 4,
    link: 'https://www.mofa.go.kr/www/main.do',
    image: Company5,
  },
  {
    id: 5,
    link: 'https://www.acrc.go.kr',
    image: Company6,
  },
  {
    id: 6,
    link: 'https://www.koreanbar.or.kr/pages/main/main.asp?v=1',
    image: Company7,
  },
  {
    id: 7,
    link: 'https://www.seoulbar.or.kr/main/mainPage.do',
    image: Company8,
  },
  {
    id: 8,
    link: 'https://www.ihcf.co.kr',
    image: Company9,
  },
  {
    id: 9,
    link: 'https://www.kica.bar',
    image: Company10,
  },
  {
    id: 10,
    link: 'https://www.kpil.org',
    image: Company11,
  },
  {
    id: 11,
    link: 'https://www.humanrights.go.kr/site/main/index001',
    image: Company12,
  },
  {
    id: 12,
    link: 'https://www.klac.or.kr/',
    image: Company1,
  },
];

export const RelatedSitePage = () => {
  return (
    <Section className="items-center max-w-6xl text-left space-y-10">
      <H1>관련 사이트</H1>

      <div className="w-full flex flex-col justify-center">
        <div className="text-center">
          {relatedData.map((item: any) => (
            <div
              className="inline-block w-1/3-4 m-2 cursor-pointer"
              onClick={() => window.open(item.link, '_blank')}
            >
              <div
                className={`grid place-items-center mb-3 w-full font-medium bg-contain bg-no-repeat bg-center h-20 md:h-44 border`}
                style={{ backgroundImage: `url('${item.image}')` }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};
