import React, { FC, useEffect, useState } from 'react';
import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { XIcon } from './icons';
import { Overlay } from './Overlay';

interface CommitteePopupProps {
  open: boolean;
  onClose: () => void;
  value: string[];
  onSave: (committees: string[]) => void;
}

export const CommitteePopup: FC<CommitteePopupProps> = ({
  open,
  onClose,
  value = [],
  onSave,
}) => {
  const [committees, setCommittees] = useState<string[]>(value);
  useEffect(() => setCommittees(value), [value]);

  if (!open) return <></>;
  return (
    <div className="fixed z-10 inset-0 grid place-items-center overflow-hidden">
      <Overlay open={open} onClose={onClose} />

      <div className="relative flex flex-col space-y-4 max-w-4/5 max-h-4/5 shadow-lg rounded-lg bg-white overflow-hidden">
        <div className="flex justify-between p-8">
          <div className="flex-1" />
          <h1 className="text-2xl font-bold text-center">
            Select Committee (max.5)
          </h1>
          <div className="flex-1 flex justify-end">
            <button onClick={onClose}>
              <XIcon />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-8 text-lg overflow-y-auto">
          {allCommittees.map((committee) => (
            <Checkbox
              key={committee}
              label={committee}
              checked={committees.includes(committee)}
              disabled={
                committees.length >= 5 && !committees.includes(committee)
              }
              onChange={(e) => {
                if (e.target.checked) {
                  const newValue = [...committees, committee];
                  setCommittees(newValue);
                } else {
                  const newValue = committees.filter((c) => c !== committee);
                  setCommittees(newValue);
                }
              }}
            />
          ))}
        </div>

        <div className="flex justify-center p-8">
          <Button
            text="Save"
            className="w-80 filled-brand-1"
            onClick={() => {
              onSave(committees);
              onClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const allCommittees: string[] = [
  'Corporate/M&A',
  'Finance',
  'Dispute Resolution',
  'Competition',
  'Tax',
  'Insolvency',
  'Real Estate',
  'Technology',
  'White Collar Crimes/Criminal',
  'Employment',
  'IP',
  'International Trade',
  'Compliance',
  'Energy/Natural Resource',
  'Immigration/Family Law',
  'ESG',
  'Human Rights / Pro Bono',
  'Young Lawyers',
  'Women',
  'Corporate Counsel',
  'Law Firm Management',
];
