import React from 'react';
import { ReactComponent as LinkedInIcon } from '../assets/svg/icon-linkedin.svg';
import { ReactComponent as FacebookIcon } from '../assets/svg/icon-facebook.svg';
import { ReactComponent as TwitterIcon } from '../assets/svg/icon-twitter.svg';
import { Section } from './Section';

export const Footer = () => {
  return (
    <>
      <div className="w-full bg-gray-50">
        <Section className="text-gray-600">
          <div className="flex flex-col justify-between space-y-8 pb-8 border-b-2 border-gray-300 md:flex-row md:space-y-0">
            <div className="flex-col flex text-13">
              <img src="/images/logo.png" className="w-14" alt="logo" />

              <p className="pt-8 pb-4">
                ASEM Tower
                <br />
                517 Yeongdong-daero
                <br />
                Gangnam-gu, Seoul 06164
                <br />
                Korea
              </p>

              <a
                href="https://prairie-porcupine-6b9.notion.site/IAKL-Privacy-Policy-55b21000cae5484c9772229785335478"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
              <a
                href="https://prairie-porcupine-6b9.notion.site/IAKL-Privacy-Policy-55b21000cae5484c9772229785335478"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>

            <div className="flex space-x-4">
              <a
                href="https://www.linkedin.com/company/international-association-of-korean-lawyers-inc"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://www.facebook.com/groups/iaklgroup"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://twitter.com/iaklnews"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                <TwitterIcon />
              </a>
            </div>
          </div>

          <p className="pt-8 w-full max-w-7xl text-sm text-gray-400 text-left ">
            © 2021 International Association of Korean Lawyers. All Rights
            Reserved.
          </p>
        </Section>
      </div>
    </>
  );
};
