import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Editor } from '../../plugins/froala';
import { Labeled } from '../../components/Labeled';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import {
  postAdminH1,
  postHasContent,
  postHasDate,
  postHasDescription,
  postHasPriority,
  postHasSubType,
  postHasThumbnail,
  postHasUrl,
  postPath,
  usePostType,
} from '../../post-config';
import { Post, PostSubType } from '../../types';
import { AdminH1 } from '../components/AdminH1';
import { ImageUplaod } from '../../components/ImageUpload';
import { Select } from '../../components/Select';

export const PostEdit = () => {
  const { push } = useHistory();
  const postType = usePostType(true);
  const { id } = useParams<{ id: string }>();
  const { data: post } = useQuery<Post>(`/admin/posts/${id}`, fetcher);
  const [title, setTitle] = useState('');
  const [subType, setSubType] = useState<string>();
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [dateString, setDateString] = useState('');
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [priority, setPriority] = useState(0);

  const [content, setContent] = useState('');
  useEffect(() => {
    if (!post) return;
    setTitle(post.title);
    setSubType(post.subType);
    setDescription(post.description);
    setUrl(post.url);
    setDateString(post.dateString);
    setPriority(post.priority);
    setContent(post.content);
  }, [post]);

  async function save() {
    const patchData: any = {
      subType,
      title,
      description,
      url,
      priority,
      dateString,
      content,
    };
    if (thumbnail) {
      const formData = new FormData();
      formData.append('file', thumbnail);
      const { data: url } = await api.post('/s3/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      patchData.thumbnail = url;
    }
    await api.patch(`/admin/posts/${id}`, patchData);
    push(`/admin/${postPath(postType)}/${id}`);
  }

  if (!post) return <></>;
  return (
    <>
      <AdminH1>{postAdminH1(postType)}</AdminH1>

      <Card>
        <div className="space-y-6 px-4 py-6 sm:px-6 md:px-8">
          {postHasSubType(postType) && (
            <Select
              label="Select"
              value={subType}
              onChange={(e) => setSubType(e.target.value)}
            >
              <option
                label={PostSubType.LEGAL_PRACTICE_DIVISION}
                value={PostSubType.LEGAL_PRACTICE_DIVISION}
              />
              <option
                label={PostSubType.PUBLIC_AND_PROFESSIONAL_INTEREST_DIVISION}
                value={PostSubType.PUBLIC_AND_PROFESSIONAL_INTEREST_DIVISION}
              />
            </Select>
          )}
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {postHasDate(postType) && (
            <TextField
              label="Date"
              value={dateString}
              onChange={(e) => setDateString(e.target.value)}
            />
          )}
          {postHasPriority(postType) && (
            <TextField
              label="Priority"
              type="number"
              value={priority}
              onChange={(e) => setPriority(Number(e.target.value))}
            />
          )}
          {postHasDescription(postType) && (
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          )}
          {postHasUrl(postType) && (
            <TextField
              label="URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          )}
          {postHasThumbnail(postType) && (
            <ImageUplaod
              label="Thumbnail"
              file={thumbnail}
              onChange={(e) =>
                e.target.validity.valid &&
                e.target.files &&
                setThumbnail(e.target.files.item(0))
              }
            />
          )}
          {postHasContent(postType) && (
            <Labeled label="Content">
              <Editor
                model={content}
                onModelChange={(model: any) => setContent(model)}
              />
            </Labeled>
          )}
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="Cancel"
            to={`/admin/${postPath(postType)}/${id}`}
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
          />
          <Button
            text="Save"
            className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
            disabled={!title}
            onClick={save}
          />
        </div>
      </Card>
    </>
  );
};
