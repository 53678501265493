import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/files_manager.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import React, { useEffect, useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { api } from './axios';

export const Editor = (props: any) => {
  const [signature, setSignature] = useState<any>();
  useEffect(() => {
    if (signature) return;
    api.get('/froala/signature').then(({ data }) => setSignature(data));
  }, [signature]);

  const config = Object.assign(
    {
      attribution: false,
      filesManagerAllowedTypes: ['image/*'],
      imageEditButtons: ['imageDisplay', 'imageAlign', 'imageRemove'],
      imageUploadToS3: signature,
      key: process.env.REACT_APP_FROALA_KEY,
    },
    props.config,
  );

  return signature ? (
    <FroalaEditor
      config={config}
      model={props.model}
      onModelChange={props.onModelChange}
    />
  ) : (
    <></>
  );
};

export { FroalaEditorView as EditorView };
