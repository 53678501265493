import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Card } from '../components/Card';
import { H1 } from '../components/H1';
import { Pagination } from '../components/Pagination';
import { Section } from '../components/Section';
import { Table } from '../components/Table';
import { useQueryString } from '../hooks';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { fetcher } from '../plugins/react-query';
import { Notice, Paginated } from '../types';

export const NoticesPage = () => {
  const limit = 12;
  const queryString = useQueryString({
    limit,
    filter: {
      isHide: false,
    },
  });

  const { data: notices } = useQuery<Paginated<Notice>>(
    `/notices${queryString}`,
    fetcher,
    { keepPreviousData: true },
  );

  if (!notices) return <></>;
  return (
    <Section>
      <div className="flex flex-col items-center space-y-8 mb-14">
        <H1>공지사항</H1>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>번호</Table.Th>
              <Table.Th>제목</Table.Th>
              <Table.Th>생성일</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {notices?.items.map((notice) => (
              <Table.Row key={notice.id}>
                <Table.Td>{notice.id}</Table.Td>
                <Table.Td>{notice.title}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(notice.createdAt, MomentFormat.YYYYMMDD)}
                </Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/notices/${notice.id}?local=KO`}
                  >
                    자세히 보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={notices.items.length}
            total={notices.total}
          />
          <Pagination.Nav
            basePath="/notices"
            total={notices.total}
            local={'KO'}
          />
        </Pagination>
      </Card>
    </Section>
  );
};
