import { ComponentType, FC } from 'react';
import { Redirect, Route } from 'react-router';
import { useAuth } from '../hooks';
import { languageState } from '../plugins/ridge';

export interface AuthRouteProps {
  path: string;
  component: ComponentType;
  guestOnly?: boolean;
}

export const AuthRoute: FC<AuthRouteProps> = ({
  path,
  component: Component,
  guestOnly = false,
}) => {
  const { authenticated } = useAuth();
  const language = languageState.useValue();

  return (
    <Route
      path={path}
      render={() => {
        if (guestOnly && authenticated) {
          return (
            <Redirect
              to={language === 'KO' ? '/greeting?local=KO' : '/?local=EN'}
            />
          );
        }
        if (!guestOnly && !authenticated) {
          return <Redirect to="/signin" />;
        }
        return <Component />;
      }}
    />
  );
};
