import React from 'react';
import { Button } from '../components/Button';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';

export const JoinUsPendingPage = () => {
  return (
    <Section className="max-w-3xl items-center text-center">
      <H1>Confirmation Pending</H1>
      <p className="text-17 text-gray-600 pt-8 pb-32">
        Thank you for submitting your details.
        <br />
        We will review your profile and send you a confirmation of your
        membership promptly.
        <br />
        <br />
        We look forward to welcoming you to
        <br />
        our community.
      </p>
      <Button text="Main →" to="/" className="w-80 filled-brand-1" />
    </Section>
  );
};
