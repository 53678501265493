import React from 'react';

export const PrivacyPolicyPage = () => {
  return (
    <>
      <iframe
        src="https://docs.google.com/document/d/12UWvGo9G9C17Q3eIPoPzf856-h3HbhzlF5RZ1bYIgaE"
        className="w-full h-256"
      />
    </>
  );
};
