import React, { forwardRef, SelectHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  helper?: string;
}

/**
 * @example
 * <Select label="Select" onChange={(e) => console.log(e.target.value)}>
 *   <option label="option 1" value="1" />
 *   <option label="option 2" value="2" />
 *   <option label="option 3" value="3" />
 * </Select>
 */
export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ children, className = '', label, helper, ...props }, ref) => {
    const [id] = useId(1, 'select');

    return (
      <div className="label-col">
        {label && <Label htmlFor={id} text={label} />}
        <select
          ref={ref}
          id={id}
          className={`select ${helper ? 'border-error' : ''} ${className}`}
          {...props}
        >
          {children}
        </select>
        {helper && <p className="text-sm text-error">{helper}</p>}
      </div>
    );
  },
);
