import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import { fetcher } from '../../plugins/react-query';
import { User, UserType } from '../../types';
import { AdminH1 } from '../components/AdminH1';

export const UserShow = () => {
  const { id } = useParams<{ id: string }>();
  const { data: user } = useQuery<User>(`/admin/users/${id}`, fetcher, {});

  if (!user) return <></>;
  return (
    <>
      <AdminH1>User Details</AdminH1>

      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-3 md:px-8">
          <TextField label="Email" type="email" value={user.email} disabled />
          <TextField label="Role" value={user.role} disabled />
          <TextField label="Type" value={user.type} disabled />
          <TextField label="First Name" value={user.firstName} disabled />
          <TextField label="Last Name" value={user.lastName} disabled />
          <TextField label="Salutation" value={user.salutation} disabled />
          <TextField label="School" value={user.school} disabled />
          {user.type === UserType.PROFESSIONAL && (
            <>
              <TextField label="Graduation" value={user.graduation} disabled />
              <TextField label="Country" value={user.country} disabled />
              <TextField label="License" value={user.license} disabled />
              <TextField
                label="Company Name"
                value={user.companyName}
                disabled
              />
              <div className="col-span-2">
                <TextField
                  label="Company Address"
                  value={user.companyAddress}
                  disabled
                />
              </div>
              <TextField
                label="Office Number"
                value={user.officeNumber}
                disabled
              />
            </>
          )}
          {user.type === UserType.STUDENT && (
            <TextField label="Class Year" value={user.classYear} disabled />
          )}
          <TextField label="Mobile Number" value={user.mobileNumber} disabled />
          <TextField label="Languages" value={user.languages} disabled />
          <TextField label="Latitude" value={user.lat} disabled />
          <TextField label="Longitude" value={user.lng} disabled />
        </div>

        <div className="px-4 py-6 sm:px-6 md:px-8">
          <div className="flex space-x-2">
            <span>Exposed</span>
            <Toggle checked={user.exposed} disabled />
            <p className="text-gray-400">
              {user.consentToExposure
                ? '(User consent to exposure)'
                : '(User declined to consent to exposure)'}
            </p>
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="Edit"
            to={`/admin/users/${id}/edit`}
            className="h-10 text-sm filled-indigo-600 hover:bg-indigo-700"
          />
        </div>
      </Card>
    </>
  );
};
