import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { allPracticeAreas, countries, User } from '../types';
import { DirectoryCard } from './DirectoryCard';
import { Search } from './Search';
import { Select } from './Select';

export const DirectorySearch = () => {
  const [country, setCountry] = useState('Country');
  const [practiceArea, setPracticeArea] = useState('Practice Area');
  const [q, setQ] = useState('');
  const { data: users = [] } = useQuery<User[]>('/users', fetcher);

  const filteredUsers =
    country === 'Country' && practiceArea === 'Practice Area' && q.trim() === ''
      ? []
      : users.filter(
          (user) =>
            (country === 'Country' || country === user.country) &&
            (practiceArea === 'Practice Area' ||
              user.practiceAreas.includes(practiceArea)) &&
            (q.trim() === '' ||
              `${user.firstName} ${user.lastName}`.includes(q) ||
              user.email.includes(q) ||
              user.companyName.includes(q)),
        );

  return (
    <>
      <div className="md:w-2/3 md:grid md:grid-cols-2 gap-4 flex flex-col">
        <div className="grid grid-cols-2 gap-4">
          <Select
            placeholder="Country"
            onChange={(e) => setCountry(e.target.value)}
          >
            <option>Country</option>
            {countries.map((country) => (
              <option
                key={`${country.name}${country.code}${country.abbreviation}`}
                value={country.name}
                disabled={!!country.disabled}
              >{`${country.abbreviation ? ' - ' : ''}${country.name}`}</option>
            ))}
          </Select>

          <Select
            placeholder="Practice Area"
            onChange={(e) => setPracticeArea(e.target.value)}
          >
            <option>Practice Area</option>
            {allPracticeAreas.map((practiceArea) => (
              <option key={practiceArea} value={practiceArea}>
                {practiceArea}
              </option>
            ))}
          </Select>
        </div>

        <div className="">
          <Search placeholder="Search" onChange={(e) => setQ(e.target.value)} />
        </div>
      </div>

      {filteredUsers.length === 0 && (
        <div className="text-center w-full pt-24 text-gray-500 font-light">
          {q ? 'No result' : 'Enter search keyword'}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-4 grid-flow-row gap-4 w-full pb-80 pt-6">
        {filteredUsers.map((user) => (
          <DirectoryCard key={user.id} user={user} />
        ))}
      </div>
    </>
  );
};
