import React, { ComponentType, FC } from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route } from 'react-router';
import { useAuth } from '../hooks';
import { fetcher } from '../plugins/react-query';
import { languageState } from '../plugins/ridge';
import { memberOnlyPage, usePostType } from '../post-config';
import { Role, User } from '../types';

export interface MemberRouteProps {
  path: string | string[];
  component: ComponentType;
}

export const MemberRoute: FC<MemberRouteProps> = ({
  path,
  component: Component,
}) => {
  let isPublic: boolean;
  try {
    const postType = usePostType();
    isPublic = !memberOnlyPage(postType);
  } catch (error) {
    isPublic = false;
  }
  const language = languageState.useValue();
  const { authenticated } = useAuth();
  const { data: me, status } = useQuery<User>('/users/me', fetcher, {
    enabled: authenticated,
  });

  return (
    <Route
      path={path}
      render={() => {
        if (isPublic) return <Component />;
        if (!authenticated) {
          alert('Member Only');
          return (
            <Redirect
              to={language === 'KO' ? '/greeting?local=KO' : '/?local=EN'}
            />
          );
        }
        if (status === 'loading') return <></>;
        if (
          status === 'success' &&
          me &&
          [Role.ADMIN, Role.MEMBER].includes(me.role)
        ) {
          return <Component />;
        }
        alert('Member Only');
        return (
          <Redirect
            to={language === 'KO' ? '/greeting?local=KO' : '/?local=EN'}
          />
        );
      }}
    />
  );
};
