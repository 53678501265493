import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';
import { Select } from '../components/Select';
import { TextField } from '../components/TextField';
import { api } from '../plugins/axios';
import { salutationOthers, salutations } from '../types';

interface FormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  salutation: string;
  otherSalutation: string;
  privacy: boolean;
}

export const JoinUsOnePage = () => {
  const { push } = useHistory();
  const {
    watch,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { salutation: salutations[0] } });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        api
          .post('/users/check-email', { email: data.email })
          .then(() =>
            push('/joinus/2', {
              ...data,
              salutation:
                data.salutation === salutationOthers
                  ? data.otherSalutation
                  : data.salutation,
            }),
          )
          .catch(
            (e) =>
              e.response.status === 409 &&
              setError('email', {
                message: 'A user whit this email address already exists',
              }),
          ),
      )}
    >
      <Section className="max-w-md space-y-6">
        <div>
          <H1>Join the IAKL</H1>
          <p className="pb-4 text-17 text-center text-gray-500">
            Search, connect and engage with Korean lawyers worldwide
          </p>
        </div>

        <TextField
          type="email"
          label="Email"
          placeholder="you@example.com"
          helper={errors.email?.message}
          {...register('email', { required: 'This field is required' })}
        />
        <TextField
          type="password"
          label="Password"
          placeholder="Enter your password here."
          helper={errors.password?.message}
          {...register('password', {
            required: 'This field is required',
            minLength: {
              value: 6,
              message: 'Please enter at least 6 characters',
            },
            maxLength: {
              value: 15,
              message: 'Password length must be 15 or less',
            },
          })}
        />

        <p className="text-17 text-center text-gray-500 pt-14">
          Cultivate friendships, find new opportunities and contribute to the
          betterment of our global community
        </p>

        <TextField
          label="First Name"
          helper={errors.firstName?.message}
          {...register('firstName', { required: 'This field is required' })}
        />
        <TextField
          label="Last Name"
          helper={errors.lastName?.message}
          {...register('lastName', { required: 'This field is required' })}
        />

        <div className="flex flex-col space-y-2">
          <Select
            label="How would you like to be addressed?"
            {...register('salutation')}
          >
            {salutations.map((s) => (
              <option key={s}>{s}</option>
            ))}
            <option>{salutationOthers}</option>
          </Select>

          {watch('salutation') === salutationOthers && (
            <TextField
              placeholder="Please enter your preferred salutation here."
              {...register('otherSalutation')}
            />
          )}
        </div>

        <div className="pb-24">
          <div className="flex space-x-2">
            <Checkbox
              className="rounded-full"
              helper={errors.privacy?.message}
              {...register('privacy', {
                required: 'Please accept privacy policy',
              })}
            />
            <Link
              to="/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Privacy Policy
            </Link>
          </div>
        </div>

        <Button text="Next" className="w-full filled-brand-1" />
      </Section>
    </form>
  );
};
