import React from 'react';
import { useQuery } from 'react-query';
import HeroImg from '../assets/img/committees-hero.png';
import { CommitteeCard } from '../components/CommitteeCard';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';
import { useQueryString } from '../hooks';
import { fetcher } from '../plugins/react-query';
import { usePostType } from '../post-config';
import { Paginated, Post, PostSubType } from '../types';

export const CommitteesPage = () => {
  const postType = usePostType();
  const queryString = useQueryString({
    limit: 1000,
    filter: { type: postType },
  });
  const { data } = useQuery<Paginated<Post>>(`/posts${queryString}`, fetcher, {
    keepPreviousData: true,
  });

  return (
    <>
      <Section>
        <H1 className="mb-8 md:mb-16">IAKL Committees</H1>
        <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-x-7 md:space-y-0">
          <div className="flex-1 pb-4 md:pb-0">
            <img className="w-full" src={HeroImg} alt="" />
          </div>
          <div className="flex-1">
            <p className="text-17 text-gray-600 leading-relaxed">
              Exchange specialist legal information and make international
              contacts. Communicate and connect with fellow members worldwide
              who share your interests and concerns in legal practice. The IAKL
              Committees are where birds of a feather can flock together, to
              grow and venture great distances together. Exchange conversations,
              professional or casual, with like minded people, translate theory
              into action, solve problems, find solutions and look out for that
              lightbulb moment.
              <br />
              <br /> The scene is set. 19 Committees covering prevalent practice
              areas and professional interests, chaired by leading experts. In
              an era of rapid change, meaningful growth comes from within. This
              is why we place greater emphasis on the organic growth of our
              Committees by our members as a proactive participant in leading
              authentic growth that truly serves to readying legal professionals
              of Korean heritage to succeed in the coming years.
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <H1 className="mb-16">Legal Practice Division</H1>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-7">
          {data?.items
            .filter(
              (post) => post.subType === PostSubType.LEGAL_PRACTICE_DIVISION,
            )
            .map((post) => (
              <CommitteeCard key={post.id} post={post} />
            ))}
        </div>
      </Section>

      <Section>
        <H1 className="mb-16">
          Public and Professional
          <br /> Interest Division
        </H1>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-7 ">
          {data?.items
            .filter(
              (post) =>
                post.subType ===
                PostSubType.PUBLIC_AND_PROFESSIONAL_INTEREST_DIVISION,
            )
            .map((post) => (
              <CommitteeCard key={post.id} post={post} />
            ))}
        </div>
      </Section>
    </>
  );
};
