import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ImgTwo from '../assets/img/main-img2.png';
import { Button } from '../components/Button';
import { JobCard } from '../components/JobCard';
import { Tab } from '../components/Tab';
import { Paginated, Post, PostType } from '../types';
import { Section } from '../components/Section';
import { Tabs } from '../components/Tabs';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { useQueryString } from '../hooks';
import { PostCard } from '../components/PostCard';
import { MainSlider } from '../components/MainSlider';
import { SectionSlider } from '../components/SectionSlider';
import MainImage1 from '../assets/img/main-img1.png';
import MainImage2 from '../assets/img/main-img1-2.png';
import MainImage3 from '../assets/img/main-img1-3.png';
import ProBonoImgOne from '../assets/img/sample-probono.png';
import ProBonoImgTwo from '../assets/img/sample-probono2.png';
import ProBonoImgThree from '../assets/img/sample-probono3.png';
import ProBonoImgFour from '../assets/img/sample-probono4.png';
import { MainSliderBottom } from '../components/MainSliderBottom';
const homeTabs = [
  { name: 'Conference', postType: PostType.CONFERENCE, path: '/conferences' },
  { name: 'Webinar', postType: PostType.WEBINAR, path: '/webinars' },
  {
    name: 'Social Functions',
    postType: PostType.SOCIAL_FUNCTIONS,
    path: '/socialfunctions',
  },
];

const sliderData1 = [
  {
    id: 1,
    image: MainImage1,
  },
  {
    id: 2,
    image: MainImage2,
  },
  {
    id: 3,
    image: MainImage3,
  },
];

const sliderData2 = [
  {
    id: 1,
    image: ProBonoImgOne,
  },
  {
    id: 2,
    image: ProBonoImgTwo,
  },
  {
    id: 3,
    image: ProBonoImgThree,
  },
  {
    id: 4,
    image: ProBonoImgFour,
  },
];

export const HomePage = () => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(homeTabs[0]);
  const queryString = useQueryString({
    limit: 3,
    filter: { type: selectedTab.postType },
  });
  const jobsQueryString = useQueryString({
    limit: 3,
    filter: { type: PostType.JOBS },
  });
  const { data } = useQuery<Paginated<Post>>(`/posts/${queryString}`, fetcher, {
    keepPreviousData: true,
  });
  const { data: jobsData } = useQuery<Paginated<Post>>(
    `posts/${jobsQueryString}`,
    fetcher,
  );

  return (
    <>
      {/* <MainPopUp open={isOpen} onClose={() => setIsOpen(false)} /> */}

      <MainSlider />

      <Section className="items-center space-y-4 md:flex-row md:space-x-7 md:space-y-0">
        <div className="flex-1 space-y-2 md:space-y-6">
          <h1 className="text-center md:text-left font-bold text-32 text-gray-900 md:text-52">
            Our Mission
          </h1>
          <p className="text-center md:text-left pb-4 md:pb-0 md:text-20 text-gray-700 leading-relaxed">
            We aim to connect legal professionals of Korean heritage and others
            interested in Korea to cultivate friendships, find new opportunities
            while serving as a powerful avenue for improving social justice and
            public interest.
          </p>
        </div>
        <div className="flex-1">
          <SectionSlider data={sliderData1} />
        </div>
      </Section>

      <Section className="md:items-center space-y-4 flex-col-reverse md:flex-row md:space-x-7 md:space-y-0">
        <div className="flex-1 pt-4 md:pt-0">
          <img className="w-full" src={ImgTwo} alt="Map with pins" />
        </div>
        <div className="flex-1 flex flex-col items-center md:items-start">
          <h1 className="text-center md:text-left text-32 font-bold text-gray-900 pb-5 md:text-52">
            Your digital pin-print
            <br /> on our global
            <br className="hidden md:block" /> network
          </h1>
          <Button
            text="View map"
            to="/membership-directory?local=EN"
            className="px-10 h-16 filled-brand-1"
          />
        </div>
      </Section>

      <div className="bg-brand-3">
        <Section className="items-center">
          <h1 className="text-32 font-bold pb-7 md:pb-14 md:text-52">Jobs</h1>
          <div className="grid grid-cols-1 gap-8 w-full md:grid-cols-3">
            {jobsData?.items.map((post) => (
              <JobCard key={post.id} post={post} />
            ))}
          </div>
          <button
            onClick={() => history.push('/jobs?local=EN')}
            className="text-brand-1 text-14 md:text-17 pt-9 self-end"
          >
            View more →
          </button>
        </Section>
      </div>

      <Section className="items-center">
        <h1 className="pb-7 md:pb-14 text-32 font-bold md:text-52">Events</h1>
        <Tabs className="pb-7 md:pb-14">
          {homeTabs.map((tab) => (
            <Tab
              key={tab.name}
              selected={tab.name === selectedTab.name}
              onClick={() => setSelectedTab(tab)}
              name={tab.name}
            />
          ))}
        </Tabs>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {data?.items.map((post) => (
            <PostCard key={post.id} post={post} big />
          ))}
        </div>
        <button
          onClick={() => history.push('/conferences?local=EN')}
          className="text-brand-1 text-14 md:text-17 pt-9 self-end"
        >
          View more →
        </button>
      </Section>

      <Section className="items-center space-y-4 md:flex-row md:space-x-7 md:space-y-0">
        <div className="flex-1 space-y-6">
          <h1 className="text-center md:text-left text-32 font-bold text-gray-900 md:text-52">
            Pro Bono
          </h1>
          <p className="text-center md:text-left md:text-20 text-gray-700 leading-relaxed">
            We strive to provide legal support and advocacy for matters that
            transcend borders, to serve for the advancement of social justice
            and public interest through law.
          </p>
          <div className="md:text-20">
            <button
              onClick={() => history.push('/pro-bono?local=EN')}
              className="text-brand-1 md:text-left pb-4 md:pb-0"
            >
              Supporting overseas adoptees | Legal Aid | Mentoring Program →
            </button>
          </div>
        </div>
        <div className="flex-1">
          <SectionSlider data={sliderData2} />
        </div>
      </Section>

      <MainSliderBottom />
    </>
  );
};
