import React from 'react';
import { ReactComponent as EmailIcon } from '../assets/svg/icon-email.svg';
import { ReactComponent as InfoIcon } from '../assets/svg/icon-info.svg';
import { ReactComponent as PhoneIcon } from '../assets/svg/icon-phone.svg';
import { ReactComponent as TagIcon } from '../assets/svg/icon-tag.svg';
import { User } from '../types';

interface DirectoryCardProps {
  user: User;
}

export const DirectoryCard: React.FC<DirectoryCardProps> = ({ user }) => {
  return (
    <div className="flex flex-col rounded-md bg-white border-2 border-gray-100 divide-y-2 divide-gray-100 text-gray-500">
      <div className="px-4 py-3">
        <p className="font-bold text-17 text-gray-800">
          {user.firstName} {user.lastName}
        </p>
      </div>

      <div className="space-y-1 px-4 py-3">
        <div className="flex space-x-3 text-sm">
          <div>
            <EmailIcon className="mt-0.5 wh-4" />
          </div>
          <p>{user.email}</p>
        </div>
        <div className="flex space-x-3 text-sm">
          <div>
            <InfoIcon className="mt-0.5 wh-4" />
          </div>
          <p>{user.companyName}</p>
        </div>
        <div className="flex space-x-3 text-sm">
          <div>
            <PhoneIcon className="mt-0.5 wh-4" />
          </div>
          <p>{user.officeNumber}</p>
        </div>
        <div className="flex space-x-3 text-sm">
          <div>
            <TagIcon className="mt-0.5 wh-4" />
          </div>
          <p>{user.practiceAreas?.join(', ')}</p>
        </div>
      </div>
    </div>
  );
};
