import React, { FC, useState } from 'react';
import { useEffect } from 'react';
import { Button } from '../components/Button';
import { allPracticeAreas } from '../types';
import { Checkbox } from './Checkbox';
import { XIcon } from './icons';
import { Overlay } from './Overlay';

interface PracticeAreaPopupProps {
  open: boolean;
  onClose: () => void;
  value: string[];
  onSave: (value: string[]) => void;
}

export const PracticeAreaPopup: FC<PracticeAreaPopupProps> = ({
  open,
  onClose,
  value = [],
  onSave,
}) => {
  const [practiceAreas, setPracticeAreas] = useState<string[]>(value);
  useEffect(() => setPracticeAreas(value), [value]);

  if (!open) return <></>;
  return (
    <div className="fixed z-10 inset-0 grid place-items-center overflow-hidden">
      <Overlay open={open} onClose={onClose} />

      <div className="relative flex flex-col space-y-4 max-w-4/5 max-h-4/5 shadow-lg rounded-lg bg-white overflow-hidden">
        <div className="flex justify-between p-8">
          <div className="flex-1" />
          <h1 className="text-2xl font-bold text-center">
            Practice Area/Speciality
          </h1>
          <div className="flex-1 flex justify-end">
            <button onClick={onClose}>
              <XIcon />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-8 text-lg overflow-y-auto">
          {allPracticeAreas.map((practiceArea) => (
            <Checkbox
              key={practiceArea}
              label={practiceArea}
              checked={practiceAreas.includes(practiceArea)}
              onChange={(e) => {
                if (e.target.checked) {
                  if (practiceAreas.includes(practiceArea)) return;
                  const newValue = [...practiceAreas, practiceArea];
                  setPracticeAreas(newValue);
                } else {
                  const newValue = practiceAreas.filter(
                    (p) => p !== practiceArea,
                  );
                  setPracticeAreas(newValue);
                }
              }}
            />
          ))}
        </div>

        <div className="flex justify-center p-8">
          <Button
            text="Save"
            className="w-80 filled-brand-1"
            onClick={() => {
              onSave(practiceAreas);
              onClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};
