import React, { useState } from 'react';
import { DirectoryMap } from '../components/DirectoryMap';
import { DirectorySearch } from '../components/DirectorySearch';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';
import { Tab } from '../components/Tab';
import { Tabs } from '../components/Tabs';

const tabs = ['Map', 'Search'];

export const DirectoryPage = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <Section className="items-center">
      <div className="flex flex-col space-y-8 pb-14">
        <H1>Membership Directory</H1>
        <Tabs>
          {tabs.map((tab) => (
            <Tab
              key={tab}
              selected={tab === selectedTab}
              onClick={() => setSelectedTab(tab)}
              name={tab}
            />
          ))}
        </Tabs>
      </div>

      {selectedTab === tabs[0] && <DirectoryMap />}
      {selectedTab === tabs[1] && <DirectorySearch />}
    </Section>
  );
};
