import React from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Section } from '../components/Section';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { fetcher } from '../plugins/react-query';
import { Post } from '../types';

export const PostPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: post } = useQuery<Post>(`/posts/${id}`, fetcher);

  if (!post) return <></>;
  return (
    <Section className="max-w-3xl">
      <h1 className="text-24 font-bold capitalize break-all">{post.title}</h1>
      <time className="text-15 text-gray-500 mb-8">
        {utcToLocalFormat(post.createdAt, MomentFormat.LL)}
      </time>
      <FroalaEditorView model={post.content} />
    </Section>
  );
};
