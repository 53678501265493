import React, { FC } from 'react';
import { AnimationLayout } from './AnimationLayout';

interface MainPopupModalProps {
  open: boolean;

  onClose: () => void;
}

export const MainPopupModal: FC<MainPopupModalProps> = ({
  open,

  onClose,
}) => {
  return (
    <AnimationLayout open={open} onClose={onClose}>
      <div className="relative my-20 max-w-md w-30 bg-white text-left">
        <div className="relative aspect-[1/1] w-full">
          <img
            src="/images/popup-2.jpeg"
            className="w-full"
            alt="logo"
            onClick={() => {
              window.open(
                'https://www.iakl2024.org/checkout/select-buy',
                '_blank',
              );
            }}
          />
        </div>
        <div>
          <div className="text-right">
            <button
              className="bottom-4 right-3 p-2 bg-white text-xs text-gray-500 hover:text-gray-700 "
              onClick={onClose}
            >
              Closed
            </button>
          </div>
        </div>
      </div>
    </AnimationLayout>
  );
};
