import React from 'react';

import Sponcer1 from '../assets/img/Sponcerimage1.png';
import Sponcer2 from '../assets/img/Sponcerimage2.png';
import Sponcer3 from '../assets/img/Sponcerimage3.png';
import Sponcer5 from '../assets/img/Sponcerimage5.png';
import Sponcer6 from '../assets/img/Sponcerimage6.png';
import Sponcer7 from '../assets/img/Sponcerimage7.png';
import Sponcer8 from '../assets/img/Sponcerimage8.png';
import Sponcer9 from '../assets/img/Sponcerimage9.png';
import Sponcer10 from '../assets/img/Sponcerimage10.png';
import Sponcer11 from '../assets/img/Sponcerimage11.png';
import Sponcer12 from '../assets/img/Sponcerimage12.png';
import Sponcer13 from '../assets/img/Sponcerimage13.png';
import Sponcer14 from '../assets/img/Sponcerimage14.png';
import Sponcer15 from '../assets/img/Sponcerimage15.png';
import Sponcer16 from '../assets/img/Sponcerimage16.png';
import Sponcer17 from '../assets/img/Sponcerimage17.png';
import Sponcer18 from '../assets/img/Sponcerimage18.png';
import Sponcer19 from '../assets/img/Sponcerimage19.png';
import Sponcer20 from '../assets/img/Sponcerimage20.png';
import Sponcer21 from '../assets/img/Sponcerimage21.png';
import Sponcer22 from '../assets/img/Sponcerimage22.png';
import Sponcer23 from '../assets/img/Sponcerimage23.png';
import Sponcer24 from '../assets/img/Sponcerimage24.png';
import Sponcer25 from '../assets/img/Sponcerimage25.png';
import Sponcer26 from '../assets/img/Sponcerimage26.png';
import Sponcer27 from '../assets/img/Sponcerimage27.png';
import Sponcer28 from '../assets/img/Sponcerimage28.png';

const data = [
  {
    id: 1,
    link: 'https://www.yulchon.com/en/main/main.do',
    type: 'Diamond',
    image: Sponcer1,
  },
  {
    id: 2,
    link: 'http://www.yoonyang.com/eng/main.do',
    type: 'Diamond',
    image: Sponcer2,
  },
  {
    id: 3,
    link: 'https://www.thomsonreuters.com/en.html',
    type: 'Diamond',
    image: Sponcer3,
  },
  {
    id: 5,
    link: 'https://www.bkl.co.kr/law?lang=en',
    type: 'Platinum',
    image: Sponcer5,
  },
  {
    id: 6,
    link: 'https://www.gtlaw.com/',
    type: 'Platinum',
    image: Sponcer6,
  },
  {
    id: 7,
    link: 'https://www.kimchang.com/en/main.kc',
    type: 'Platinum',
    image: Sponcer7,
  },
  {
    id: 8,
    link: 'https://kobrekim.com/',
    type: 'Platinum',
    image: Sponcer8,
  },
  {
    id: 9,
    link: 'https://www.leeko.com/newmain/index.do?lang=EN',
    type: 'Platinum',
    image: Sponcer9,
  },
  {
    id: 10,
    link: 'https://www.shinkim.com/eng/',
    type: 'Platinum',
    image: Sponcer10,
  },
  {
    id: 11,
    link: 'https://ailinggo.ai/en',
    type: 'Gold',
    image: Sponcer11,
  },
  {
    id: 12,
    link: 'https://www.barunlaw.com/?lang=EN',
    type: 'Gold',
    image: Sponcer12,
  },
  {
    id: 13,
    link: 'https://www.klpartners.com/',
    type: 'Gold',
    image: Sponcer13,
  },
  {
    id: 14,
    link: 'http://www.konkuk.ac.kr/do/Eng/Index.do',
    type: 'Gold',
    image: Sponcer14,
  },
  {
    id: 15,
    link: 'https://www.alixpartners.com/',
    type: 'Silver',
    image: Sponcer15,
  },
  {
    id: 16,
    link: 'http://www.kimchanglee.co.kr/firmprofile.htm',
    type: 'Silver',
    image: Sponcer16,
  },
  {
    id: 17,
    link: 'https://www.seoulbar.or.kr/EgovPageLink.do?link=/en/EnglishHistory',
    type: 'Silver',
    image: Sponcer17,
  },
  {
    id: 18,
    link: 'http://labpartners.co.kr/en/',
    type: 'Silver',
    image: Sponcer18,
  },
  {
    id: 19,
    link: 'https://www.koreanbar.or.kr/eng/pages/main/main.asp?v=1',
    type: 'Silver',
    image: Sponcer19,
  },
  {
    id: 20,
    link: 'https://www.dentonslee.com/en/',
    type: 'Bronze',
    image: Sponcer20,
  },
  {
    id: 21,
    link: 'https://www.dlapiper.com/en/korea/',
    type: 'Bronze',
    image: Sponcer21,
  },
  {
    id: 22,
    link: 'https://www.foxrothschild.com/',
    type: 'Bronze',
    image: Sponcer22,
  },
  {
    id: 23,
    link: 'https://legal.fronteousa.com/',
    type: 'Bronze',
    image: Sponcer23,
  },
  {
    id: 24,
    link: 'http://www.intellectualdata.kr/html/main_en.html',
    type: 'Bronze',
    image: Sponcer24,
  },
  {
    id: 25,
    link: 'http://www.kwla.or.kr/#',
    type: 'Bronze',
    image: Sponcer25,
  },
  {
    id: 26,
    link: 'https://home.kpmg/xx/en/home.html',
    type: 'Bronze',
    image: Sponcer26,
  },
  {
    id: 27,
    link: 'https://www.limnexus.com/',
    type: 'Bronze',
    image: Sponcer27,
  },
  {
    id: 28,
    link: 'https://peterandkim.com/',
    type: 'Bronze',
    image: Sponcer28,
  },
];

export const SponsorshipCard = () => {
  const newData: any = {};

  data.map((d) => {
    if (Object.keys(newData).includes(d.type)) {
      return (newData[d.type] = newData[d.type].concat(d));
    } else {
      return (newData[d.type] = [d]);
    }
  });

  return (
    <>
      {Object.keys(newData).map((d: any) => (
        <>
          <div className="w-full font-bold text-center my-4 text-24">{d}</div>
          <div className="text-center">
            {newData[d].map((item: any) => (
              <button
                className="inline-block w-1/3-4 m-2"
                onClick={() => window.open(item.link, '_blank')}
              >
                <div
                  className={`grid place-items-center mb-3 w-full rounded-lg bg-gray-200 font-medium bg-cover bg-no-repeat bg-center h-44`}
                  style={{ backgroundImage: `url('${item.image}')` }}
                ></div>

                {/* <p className="flex space-x-1 pt-1 max-w-full text-13 text-gray-500">
                {item.link}
              </p> */}

                {/* <Button
                text="Go to link"
                className="mt-4 w-full h-10 outlined-brand-1 my-5"
              /> */}
              </button>
            ))}
          </div>
        </>
      ))}
    </>
  );
};
