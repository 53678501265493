import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { H1 } from '../components/H1';
import { Pagination } from '../components/Pagination';
import { Search } from '../components/Search';
import { Section } from '../components/Section';
import { SeminarsCard } from '../components/SeminarsCard';
import { useQueryString } from '../hooks';
import { fetcher } from '../plugins/react-query';
import { Paginated, Seminar } from '../types';

export const SeminarsPage = () => {
  const limit = 12;
  const [ilike, setIlike] = useState('');
  const queryString = useQueryString({
    limit,
    filter: {
      isHide: false,
      title: { ilike },
    },
  });
  const { data: seminars } = useQuery<Paginated<Seminar>>(
    `/seminars${queryString}`,
    fetcher,
    {
      keepPreviousData: true,
    },
  );

  if (!seminars) return <></>;
  return (
    <Section>
      <div className="flex flex-col items-center space-y-8 mb-14">
        <H1>총회 및 세미나</H1>
        <div className="w-full md:w-1/2">
          <Search
            placeholder="Search"
            onChange={(e) => setIlike(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-14 w-full max-w-screen-xl">
          {seminars.items.map((seminars) => (
            <SeminarsCard key={seminars.id} seminar={seminars} />
          ))}
        </div>
      </div>
      <Pagination className="justify-center mt-8 p-0">
        <Pagination.Nav
          basePath={`/seminars`}
          local={'KO'}
          total={seminars.total}
          maxPageSetLength={5}
          defaultLimit={limit}
        />
      </Pagination>
    </Section>
  );
};
