import React, { useState } from 'react';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';
import { Tab } from '../components/Tab';
import HistoryImgOne from '../assets/img/history.ko-1.png';
import HistoryImgTwo from '../assets/img/history.ko-2.png';
import HistoryImgThree from '../assets/img/history.ko-3.png';
import HistoryImgFour from '../assets/img/history.ko-4.png';

const timelineTabs = [
  { name: '현재 - 2007' },
  { name: '2006 - 2001' },
  { name: '2000 - 1997' },
  { name: '1995 - 1987' },
];

export const HistoryPage = () => {
  const [selectedTab, setSelectedTab] = useState(timelineTabs[0]);

  return (
    <>
      <Section className="items-center max-w-6xl text-center">
        <H1>연혁</H1>
        <div className="pb-14 space-x-4 flex justify-center pt-8">
          {timelineTabs.map((tab) => (
            <Tab
              key={tab.name}
              selected={tab.name === selectedTab.name}
              onClick={() => setSelectedTab(tab)}
              name={tab.name}
            />
          ))}
        </div>

        <div className="w-full flex flex-col justify-center">
          {selectedTab.name === timelineTabs[0].name && (
            <div className="flex justify-center">
              <img src={HistoryImgFour} alt="" className="hidden md:block" />
              <img src={HistoryImgFour} alt="" className="md:hidden" />
            </div>
          )}

          {selectedTab.name === timelineTabs[1].name && (
            <div className="flex justify-center">
              <img src={HistoryImgThree} alt="" className="hidden md:block" />
              <img src={HistoryImgThree} alt="" className="md:hidden" />
            </div>
          )}

          {selectedTab.name === timelineTabs[2].name && (
            <div className="flex justify-center">
              <img src={HistoryImgTwo} alt="" className="hidden md:block" />
              <img src={HistoryImgTwo} alt="" className="md:hidden" />
            </div>
          )}

          {selectedTab.name === timelineTabs[3].name && (
            <div className="flex justify-center">
              <img src={HistoryImgOne} alt="" className="hidden md:block" />
              <img src={HistoryImgOne} alt="" className="md:hidden" />
            </div>
          )}
        </div>
      </Section>
    </>
  );
};
