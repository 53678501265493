import React from 'react';
import { Section } from '../components/Section';
import GreetingImg from '../assets/img/greeting2.png';
import { relatedData } from './RelatedSitePage';

export const GreetingPage = () => {
  return (
    <Section className=" space-y-12">
      <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-x-40 md:space-y-0">
        <div className="flex-1 space-y-16">
          <p className="font-bold text-18">
            세계한인법률가회(IAKL) 홈페이지를 방문하신 여러분을 환영합니다!
          </p>
          <p className="text-17 text-gray-600 leading-relaxed">
            세계한인법률가회(International Association of Korean Lawyers, IAKL)
            홈페이지에 오신 것을 환영합니다. IAKL은 23개국에 걸쳐 3,500명이 넘는
            회원을 보유한 법률가 단체로서, 1988년 설립된 이래 전세계에서
            활약하는 한인 법률가들의 플랫폼 역할을 수행하여 왔습니다. 우리
            협회는 세계 곳곳에서 활약하는 수만명의 한인 법률가들이 서로 연결되고
            소통함으로써 각자가 속한 지역사회는 물론 세계의 다양한 현안과 문제 –
            예컨대 차별과 편견 등-를 함께 고민하고 보편적 가치와 법치주의 정신에
            입각하여 극복하는데 기여함을 기본적인 목표로 하고 있습니다. 이를
            위하여 보다 많은 나라와 지역의 한인법률가들이 새로이 연결될 수
            있도록 다양한 지역의 대표와 회원들이 온•오프라인의 다양한 곳에서
            자유롭고 편하게 만나고 소통의 기회를 만들기 위해 더욱
            노력하겠습니다. 회원 여러분의 많은 관심과 참여를 부탁드립니다.
          </p>
          <p className="font-bold">14대 회장 김권회</p>
        </div>

        <div className="flex-1 pb-4 md:pb-0 h-2/4">
          <img className="w-full" src={GreetingImg} alt="" />
        </div>
      </div>

      <div className="w-full flex flex-col justify-center">
        <div className="text-center">
          {relatedData.map((item: any) => (
            <div
              className="inline-block md:w-1/1-4 w-1/4 m-2 cursor-pointer"
              onClick={() => window.open(item.link, '_blank')}
            >
              <div
                className={`grid place-items-center mb-3 w-full font-medium bg-contain bg-no-repeat bg-center h-20 border`}
                style={{ backgroundImage: `url('${item.image}')` }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};
