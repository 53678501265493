import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeroImg from '../assets/img/main_hero.png';
import HeroImg2 from '../assets/img/main_hero2.png';
import HeroImg3 from '../assets/img/main_hero3.png';
import HeroImg4 from '../assets/img/main_hero4.png';
import HeroImg5 from '../assets/img/main_hero5.png';
import HeroImg6 from '../assets/img/main_hero6.png';
import { Section } from './Section';
import { Button } from './Button';

const data = [
  {
    id: 1,
    image: HeroImg,
    title: 'Welcome to the International Association of Korean Lawyers',
    description:
      'We bring together legal professionals of Korean heritage, with over 3000 members in more than 20 countries worldwide.',
  },
  {
    id: 2,
    image: HeroImg2,
    title: 'Welcome to the International Association of Korean Lawyers',
    description:
      'We bring together legal professionals of Korean heritage, with over 3000 members in more than 20 countries worldwide.',
  },
  {
    id: 3,
    image: HeroImg3,
    title: 'Welcome to the International Association of Korean Lawyers',
    description:
      'We bring together legal professionals of Korean heritage, with over 3000 members in more than 20 countries worldwide.',
  },
  {
    id: 4,
    image: HeroImg4,
    title: 'Welcome to the International Association of Korean Lawyers',
    description:
      'We bring together legal professionals of Korean heritage, with over 3000 members in more than 20 countries worldwide.',
  },
  {
    id: 5,
    image: HeroImg5,
    title: 'Welcome to the International Association of Korean Lawyers',
    description:
      'We bring together legal professionals of Korean heritage, with over 3000 members in more than 20 countries worldwide.',
  },
  {
    id: 6,
    image: HeroImg6,
    title: 'Welcome to the International Association of Korean Lawyers',
    description:
      'We bring together legal professionals of Korean heritage, with over 3000 members in more than 20 countries worldwide.',
  },

];

export const MainSlider = () => {
  return (
    <Carousel indicators={false}>
      {data.map((data) => (
        <Carousel.Item>
          <div
            className="flex flex-col w-full min-h-screen bg-center bg-cover"
            style={{ backgroundImage: `url('${data.image}')` }}
          >
            <Section className="flex-1 justify-center items-start w-full">
              <div className="max-w-xl">
                <h1 className="mb-6 font-bold text-32 text-white md:text-52">
                  {data.title}
                </h1>
                <p className="mb-9 text-gray-300 text-17">{data.description}</p>
                <Button
                  text="Join Now"
                  to="/joinus/1"
                  className="px-10 h-16 text-brand-1 bg-white"
                />
              </div>
            </Section>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
