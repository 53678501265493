import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { postPath } from '../post-config';
import { Post } from '../types';

interface CommitteeCardProps {
  post: Post;
}

export const CommitteeCard: FC<CommitteeCardProps> = ({ post }) => {
  const { push } = useHistory();

  return (
    <button
      className="flex items-center bg-gray-50 rounded-lg px-4 h-28 md:px-6 md:py-16 text-left space-y-2 break-words "
      onClick={() => push(`/${postPath(post.type)}/${post.id}?local=EN`)}
    >
      <h1 className="md:text-20 font-bold">{post.title}</h1>
    </button>
  );
};
