import { useLocation } from 'react-router';
import { PostType } from './types';

export function usePostType(isAdmin?: boolean) {
  const { pathname } = useLocation();
  const splited = pathname.split('/');
  const path = isAdmin ? splited[2] : splited[1];
  switch (path) {
    case 'recentnews':
      return PostType.RECENT_NEWS;
    case 'jobs':
      return PostType.JOBS;
    case 'conferences':
      return PostType.CONFERENCE;
    case 'webinars':
      return PostType.WEBINAR;
    case 'socialfunctions':
      return PostType.SOCIAL_FUNCTIONS;
    case 'newsletters':
      return PostType.NEWS_LETTER;
    case 'publications':
      return PostType.PUBLICATIONS;
    case 'gallery':
      return PostType.GALLERY;
    case 'committees':
      return PostType.COMMITTEES;
    case 'sponsorships':
      return PostType.SPONSORSHIP_OPTIONS;
    default:
      throw new Error();
  }
}

export function postH1(postType: PostType) {
  switch (postType) {
    case PostType.RECENT_NEWS:
      return 'IAKL Recent News';
    case PostType.JOBS:
      return 'Jobs';
    case PostType.CONFERENCE:
      return 'Events';
    case PostType.WEBINAR:
      return 'Events';
    case PostType.SOCIAL_FUNCTIONS:
      return 'Events';
    case PostType.NEWS_LETTER:
      return 'Resources';
    case PostType.PUBLICATIONS:
      return 'Resources';
    case PostType.GALLERY:
      return 'Gallery';
    case PostType.COMMITTEES:
      return 'Committees';
    case PostType.SPONSORSHIP_OPTIONS:
      return 'Sponsorship';
    default:
      throw new Error();
  }
}

export function postAdminH1(postType: PostType) {
  switch (postType) {
    case PostType.RECENT_NEWS:
      return 'Recent News';
    case PostType.JOBS:
      return 'Jobs';
    case PostType.CONFERENCE:
      return 'Conference';
    case PostType.WEBINAR:
      return 'Webinar';
    case PostType.SOCIAL_FUNCTIONS:
      return 'Social Functions';
    case PostType.NEWS_LETTER:
      return 'Newsletter';
    case PostType.PUBLICATIONS:
      return 'Publications';
    case PostType.GALLERY:
      return 'Gallery';
    case PostType.COMMITTEES:
      return 'Committees';
    case PostType.SPONSORSHIP_OPTIONS:
      return 'Sponsorship options';
    default:
      throw new Error();
  }
}

export function postPath(postType: PostType) {
  switch (postType) {
    case PostType.RECENT_NEWS:
      return 'recentnews';
    case PostType.JOBS:
      return 'jobs';
    case PostType.CONFERENCE:
      return 'conferences';
    case PostType.WEBINAR:
      return 'webinars';
    case PostType.SOCIAL_FUNCTIONS:
      return 'socialfunctions';
    case PostType.NEWS_LETTER:
      return 'newsletters';
    case PostType.PUBLICATIONS:
      return 'publications';
    case PostType.GALLERY:
      return 'gallery';
    case PostType.COMMITTEES:
      return 'committees';
    case PostType.SPONSORSHIP_OPTIONS:
      return 'sponsorships';
    default:
      throw new Error();
  }
}

export const postPaths = Object.values(PostType).map((postType) =>
  postPath(postType),
);

export function memberOnlyPage(postType: PostType) {
  return [PostType.JOBS, PostType.SOCIAL_FUNCTIONS, PostType.GALLERY].includes(
    postType,
  );
}

export function memberOnlyPost(postType: PostType) {
  return [PostType.CONFERENCE, PostType.WEBINAR].includes(postType);
}

export function postHasSubType(postType: PostType) {
  return [PostType.COMMITTEES].includes(postType);
}

export function postHasDescription(postType: PostType) {
  return [PostType.JOBS].includes(postType);
}

export function postHasUrl(postType: PostType) {
  return [
    PostType.WEBINAR,
    PostType.SPONSORSHIP_OPTIONS,
    PostType.NEWS_LETTER,
  ].includes(postType);
}

export function postHasDate(postType: PostType) {
  return [PostType.CONFERENCE, PostType.WEBINAR].includes(postType);
}

export function postHasPriority(postType: PostType) {
  return [PostType.SOCIAL_FUNCTIONS].includes(postType);
}

export function postHasThumbnail(postType: PostType) {
  return [
    PostType.RECENT_NEWS,
    PostType.JOBS,
    PostType.CONFERENCE,
    PostType.WEBINAR,
    PostType.SOCIAL_FUNCTIONS,
    PostType.NEWS_LETTER,
    PostType.PUBLICATIONS,
    PostType.GALLERY,
  ].includes(postType);
}

export function postHasContent(postType: PostType) {
  return [
    PostType.RECENT_NEWS,
    PostType.JOBS,
    PostType.CONFERENCE,
    PostType.SOCIAL_FUNCTIONS,
    PostType.PUBLICATIONS,
    PostType.GALLERY,
    PostType.COMMITTEES,
  ].includes(postType);
}
