import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FC } from 'react';

interface SectionSliderProps {
  data: any;
}

export const SectionSlider: FC<SectionSliderProps> = ({ data }) => {
  return (
    <Carousel indicators={false} controls={false} interval={2000}>
      {data.map((d: any) => (
        <Carousel.Item>
          <img src={d.image} alt="High five" />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
