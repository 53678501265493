import React from 'react';
import { ReactComponent as TickIcon } from '../assets/svg/icon-tick.svg';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';

export const ProBonoPage = () => {
  return (
    <Section className="items-center">
      <div className="flex flex-col items-center space-y-8 pb-14 max-w-3xl text-center">
        <H1>Pro Bono</H1>
        <p className="text-20 text-gray-600">
          We strive to provide legal support and advocacy for matters that
          transcend borders, to serve for the advancement of social justice and
          public interest through law.
        </p>
      </div>

      <div className="flex flex-col md:space-y-0 space-y-4 md:flex-row md:space-x-6 text-left pt-14">
        <div className="bg-gray-50 px-6 py-8 rounded-lg space-y-4 flex-1">
          <TickIcon />
          <h2 className="text-17 font-bold">Supporting Overseas Adoptees</h2>
          <p className="text-15 text-gray-600">
            We support and assist overseas Korean adoptees returning to or
            living in Korea understand the agencies, regulations and process
            involved and deal with various legal issues.
          </p>
        </div>
        <div className="bg-gray-50 px-6 py-8 rounded-lg space-y-4 flex-1">
          <TickIcon />
          <h2 className="text-17 font-bold">Mentoring Program</h2>
          <p className="text-15 text-gray-600">
            We help law school students develop their interest in international
            legal practice by connecting our members with students to share
            their experiences.
          </p>
        </div>
        <div className="bg-gray-50 px-6 py-8 rounded-lg space-y-4 flex-1">
          <TickIcon />
          <h2 className="text-17 font-bold">Legal Aid</h2>
          <p className="text-15 text-gray-600">
            We provide free legal counsel to overseas Korean nationals as well
            as english legal counseling for foreign immigrant workers and
            multicultural families in Korea.
          </p>
        </div>
      </div>
    </Section>
  );
};
