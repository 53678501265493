import React, {
  Children,
  cloneElement,
  FC,
  HTMLAttributes,
  isValidElement,
} from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';

export interface LabeledProps extends HTMLAttributes<HTMLDivElement> {
  prefix?: string;
  label?: string;
  reverse?: boolean;
  labelClassName?: string;
}

export const Labeled: FC<LabeledProps> = ({
  children,
  prefix,
  label,
  reverse = false,
  className = '',
  labelClassName = '',
  ...props
}) => {
  const [id] = useId(1, prefix);

  const labelElement = (
    <Label htmlFor={id} text={label} className={labelClassName} />
  );

  return (
    <div className={`labeled ${className}`} {...props}>
      {!reverse && labelElement}
      {Children.map(children, (child) =>
        isValidElement(child) ? cloneElement(child, { id }) : child,
      )}
      {reverse && labelElement}
    </div>
  );
};
