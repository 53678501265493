import React from 'react';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';
import OrganizationImg from '../assets/img/organization.png';

export const OrganizationPage = () => {
  return (
    <Section className="items-center max-w-6xl text-left space-y-10">
      <H1>조직도</H1>
      <p className="text-15">
        세계한인법률가회(IAKL) 의 한국 임원진을 소개합니다.
        <br />
        <br />
        IAKL 리더십은 한국 및 해외 임원진으로 구성되어 있으며,
        공동회장(Co-President) 시스템으로 운영되고 있습니다.
        <br />
        회장의 임기는 2년이며, 연차총회 개최시 교차로 선출됩니다.
      </p>
      <div className="w-full flex flex-col justify-center">
        <div className="flex justify-center">
          <img src={OrganizationImg} alt="" className="hidden md:block" />
          <img src={OrganizationImg} alt="" className="md:hidden" />
        </div>
      </div>
    </Section>
  );
};
