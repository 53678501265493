import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Labeled } from '../../components/Labeled';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import { api } from '../../plugins/axios';
import { Editor } from '../../plugins/froala';
import { fetcher } from '../../plugins/react-query';
import { Notice } from '../../types';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  title: string;
  context: string;
  isHide: boolean;
}

export const NoticeEdit = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [context, setContext] = useState('');

  const { data: notice, refetch } = useQuery<Notice>(
    `/admin/notices/${id}`,
    fetcher,
    {
      enabled: !!id,
    },
  );

  const [hide, setHide] = useState(notice?.isHide || false);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (notice) {
      setValue('title', notice.title);
      setHide(notice.isHide);
      setContext(notice.context);
    }
  }, [notice, setValue]);

  if (!notice) return <></>;
  return (
    <>
      <AdminH1>Notice</AdminH1>

      <form
        onSubmit={handleSubmit((data) => {
          if (!context) {
            alert('공지사항 내용을 입력해주세요.');
            return;
          }
          data.context = context;
          data.isHide = hide;

          api.patch(`/admin/notices/${id}`, data).then(async () => {
            await refetch();
            push(`/admin/notices/${id}`);
          });
        })}
      >
        <Card>
          <div className="space-y-6 px-4 py-6 sm:px-6 md:px-8">
            <TextField
              label="title"
              helper={errors.title?.message}
              {...register('title', { required: 'This field is required' })}
            />
            <Labeled label="Content">
              <Editor
                model={context}
                onModelChange={(model: any) => setContext(model)}
              />
            </Labeled>
          </div>

          <div className="px-4 py-6 sm:px-6 md:px-8">
            <div className="flex space-x-2">
              <span>IsHide</span>
              <Toggle onChange={() => setHide(!hide)} checked={hide} />
              <p className="text-gray-400">숨김 활성화 여부</p>
            </div>
          </div>

          <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
            <Button
              type="button"
              text="Cancel"
              to={`/admin/notices/${id}`}
              className="h-10 text-sm outlined-gray-800 hover:bg-gray-50"
            />
            <Button
              text="Save"
              className="h-10 text-sm filled-indigo-600 hover:bg-indigo-700"
            />
          </div>
        </Card>
      </form>
    </>
  );
};
