import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { ImageUplaod } from '../../components/ImageUpload';
import { Labeled } from '../../components/Labeled';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import { api } from '../../plugins/axios';
import { Editor } from '../../plugins/froala';
import { fetcher } from '../../plugins/react-query';
import { Seminar } from '../../types';
import { AdminH1 } from '../components/AdminH1';

export const SeminarsEdit = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [title, setTitle] = useState('');
  const [context, setContext] = useState('');
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const { data: seminar, refetch } = useQuery<Seminar>(
    `/admin/seminars/${id}`,
    fetcher,
    {
      enabled: !!id,
    },
  );
  const [isHide, setHide] = useState(seminar?.isHide);

  async function save() {
    const patchData: any = {
      title,
      context,
      isHide,
    };
    if (thumbnail) {
      const formData = new FormData();
      formData.append('file', thumbnail);
      const { data: url } = await api.post('/s3/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      patchData.thumbnail = url;
    }
    await api.patch(`/admin/seminars/${id}`, patchData);
    await refetch();
    push(`/admin/seminars/${id}`);
  }

  useEffect(() => {
    if (seminar) {
      setTitle(seminar.title);
      setHide(seminar.isHide);
      setContext(seminar.context);
    }
  }, [seminar]);

  if (!seminar) return <></>;
  return (
    <>
      <AdminH1>Seminar</AdminH1>

      <Card>
        <div className="space-y-6 px-4 py-6 sm:px-6 md:px-8">
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <ImageUplaod
            label="Thumbnail"
            file={thumbnail}
            onChange={(e) =>
              e.target.validity.valid &&
              e.target.files &&
              setThumbnail(e.target.files.item(0))
            }
          />
          <Labeled label="Content">
            <Editor
              model={context}
              onModelChange={(model: any) => setContext(model)}
            />
          </Labeled>
          <div className="px-4 py-6 sm:px-6 md:px-8">
            <div className="flex space-x-2">
              <span>IsHide</span>
              <Toggle onChange={() => setHide(!isHide)} checked={isHide} />
              <p className="text-gray-400">숨김 활성화 여부</p>
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            type="button"
            text="Cancel"
            to={`/admin/seminars/${id}`}
            className="h-10 text-sm outlined-gray-800 hover:bg-gray-50"
          />
          <Button
            text="Save"
            className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
            disabled={!context || !title}
            onClick={save}
          />
        </div>
      </Card>
    </>
  );
};
