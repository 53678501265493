import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { Button } from '../components/Button';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';
import { TextField } from '../components/TextField';
import { useAuth } from '../hooks';
import { UserType } from '../types';

interface FormValues {
  school: string;
  classYear: string;
  mobileNumber: string;
  languages: string;
  type: string;
}

export const JoinUsStudentPage = () => {
  const { state } = useLocation<any>();
  const { push } = useHistory();
  const { signup } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { type: UserType.STUDENT } });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        signup({ ...state, ...data }).then(() => push('/joinus/pending')),
      )}
    >
      <Section className="max-w-md space-y-8">
        <div>
          <H1>Welcome!</H1>
          <p className="text-center text-gray-500 pt-4 pb-8">I am a student</p>
        </div>

        <div className="flex flex-col space-y-3">
          <TextField
            label="Where do you study law?"
            placeholder="Name of School"
            helper={errors.school?.message}
            {...register('school', { required: 'This field is required' })}
          />
          <TextField
            placeholder="Class Year"
            helper={errors.classYear?.message}
            {...register('classYear', { required: 'This field is required' })}
          />
        </div>

        <TextField label="Mobile number" {...register('mobileNumber')} />

        <TextField label="Languages" {...register('languages')} />

        <Button text="Join" className="w-full filled-brand-1" />
      </Section>
    </form>
  );
};
