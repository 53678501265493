import React from 'react';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';
import { SponsorshipCard } from '../components/SponsorshipCard';
import { postH1 } from '../post-config';
import { PostType } from '../types';

export const SponsorshipPage = () => {
  const postType = PostType.SPONSORSHIP_OPTIONS;

  return (
    <>
      <Section className="items-center space-y-8 text-center">
        <H1>{postH1(postType)}</H1>
        <p className="md:text-20 text-gray-600 max-w-4xl leading-relaxed">
          Much of the recent emphasis on environmental, social and governance
          considerations for businesses has provided momentum for environmental
          and social standards to enter the business of law firms. Becoming an
          IAKL sponsor will be a demonstration of your commitment and interest
          in projects that are seen as good for the betterment of our society.
          <br />
          <br />
          Inherently international, the IAKL has served as a forum for knowledge
          exchange and an avenue for improving social justice and public service
          on matters that transcend borders. By bringing together lawyers of
          Korean heritage we have been able to address such topics that go
          beyond local/national pro bono efforts. <br />
          <br /> International legal aid, supporting global adoptees and
          immigration workers as well as working with the Overseas Koreans
          Foundation and the Ministry of Foregin Affairs to help and support
          Korean nationals working or Overseas Koreans Foundation are some of
          the key initiatives that we continue to focus on. <br />
          <br /> We also serve as a bridge for students interested in
          international legal practice by providing mentoring programs,
          scholarships and overseas work experiences for young lawyers.
        </p>
      </Section>

      <Section className="items-center space-y-8 text-center">
        <h2 className="text-24 text-gray-800 text-center">
          Sponsorship benefits
        </h2>
        <p className=" text-gray-600 max-w-4xl leading-relaxed">
          Much of the recent emphasis on environmental, social and governance
          considerations for businesses has provided momentum for environmental
          and social standards to enter the business of law firms. Becoming an
          IAKL sponsor will be a demonstration of your commitment and interest
          in projects that are seen as good for the betterment of our society.
          <br />
          <br />
          Inherently international, the IAKL has served as a forum for knowledge
          exchange and an avenue for improving social justice and public service
          on matters that transcend borders. By bringing together lawyers of
          Korean heritage we have been able to address such topics that go
          beyond local/national pro bono efforts. <br />
          <br /> International legal aid, supporting global adoptees and
          immigration workers as well as working with the Overseas Koreans
          Foundation and the Ministry of Foregin Affairs to help and support
          Korean nationals working or Overseas Koreans Foundation are some of
          the key initiatives that we continue to focus on. <br />
          <br /> We also serve as a bridge for students interested in
          international legal practice by providing mentoring programs,
          scholarships and overseas work experiences for young lawyers.
        </p>
      </Section>

      <Section>
        <SponsorshipCard />

        <p className="text-center pt-28">
          For more information please contact us as
          <a
            className="pl-2 underline text-brand-1"
            href="mailto:iakl@iakl.net"
          >
            iakl@iakl.net
          </a>
        </p>
      </Section>
    </>
  );
};
