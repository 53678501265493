import React, { FC, HTMLAttributes } from 'react';

export interface OverlayProps extends HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  onClose?: () => void;
}

export const Overlay: FC<OverlayProps> = ({
  className = '',
  open,
  onClose = () => {},
  ...props
}) => {
  return (
    <div
      className={`fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity ease-linear duration-300 ${
        open
          ? 'pointer-events-auto opacity-100'
          : 'pointer-events-none opacity-0'
      } ${className}`}
      onClick={onClose}
      {...props}
    />
  );
};
