import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { Seminar, } from '../types';

export interface SeminarCardProps {
    seminar: Seminar;
}

export const SeminarsCard: FC<SeminarCardProps> = ({ seminar }) => {
    const { push } = useHistory();
    return (
        <button
            className="flex flex-col text-left"
            onClick={() => push(`/seminars/${seminar.id}?local=KO`)}
        >
            {seminar.thumbnail ? (
                <img
                    src={seminar.thumbnail}
                    alt={seminar.title}
                />
            ) : (
                <div
                    className={`grid place-items-center mb-3 w-full rounded-lg bg-gray-200 font-medium text-48 text-white ${'h-44'}`}
                >
                    Image
                </div>
            )}
            <h1 className="font-bold text-20 truncate-2-lines">{seminar.title}</h1>
            <p className="text-16 text-gray-500 uppercase truncate-3-lines">
                {seminar.description}
            </p>
            <p className="flex space-x-1 pt-1 max-w-full text-13 text-gray-500">
                <time className="text-14 text-gray-500 justify-self-end">
                    {utcToLocalFormat(seminar.createdAt, MomentFormat.LL)}
                </time>
            </p>
        </button>
    );
};


