import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Admin } from './admin/Admin';
import { AdminRoute } from './components/AdminRoute';
import { Router } from './pages/Router';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AdminRoute path="/admin" component={Admin} />
        <Route path="/" component={Router} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
